// @import dependencies
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

// @import paths
import { authPaths } from '@routes/paths';

// @import Reducers
import { selectUserData } from '@redux/slices/user';

export default function PlanGuard({ children }) {
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);
  const { user, loading } = useSelector(selectUserData);
  const avoidRoutes = [
    authPaths.signUpPlan,
    authPaths.reusableComponents,
    authPaths.billingRedirection,
    authPaths.success,
  ];
  useEffect(() => {
    if (!loading && user?.id) setLoaded(true);
  }, [loading, user]);

  if (loaded && user?.id) {
    if (!avoidRoutes.includes(pathname) && !user.plan_name) {
      return <Navigate to={authPaths.signUpPlan} />;
    }
    return <>{children}</>;
  }
}

PlanGuard.propTypes = {
  children: PropTypes.node,
};
