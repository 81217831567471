const routes = {
  'GET_api-integration/': /^GET_api-integration\/$/,
  'POST_api-integration/': /^POST_api-integration\/$/,
  'POST_api-integration-connect/': /^POST_api-integration-connect\/$/,
  'GET_api-integration/products/{id}/':
    /^GET_api-integration\/products\/\d+\/(\?page=\d+)?(&page_size=\d+)?$/,
  'POST_api-integration/request-new-integration/':
    /^POST_api-integration\/request-new-integration\/$/,
  'GET_api-integration/{id}/': /^GET_api-integration\/\d+\/$/,
  'PATCH_api-integration/{id}/': /^PATCH_api-integration\/\d+\/$/,
  'DELETE_api-integration/{id}/': /^DELETE_api-integration\/\d+\/$/,
  'POST_api-integration/request-custom-integration':
    /^POST_api-integration\/request-custom-integration\/$/,
  'POST_auth/change-password': /^POST_auth\/change-password$/,
  'GET_auth/email-verification': /^GET_auth\/email-verification$/,
  'GET_auth/environment-variables': /^GET_auth\/environment-variables$/,
  'POST_auth/forgot-password': /^POST_auth\/forgot-password$/,
  'POST_auth/google': /^POST_auth\/google$/,
  'POST_auth/login': /^POST_auth\/login$/,
  'POST_auth/logout': /^POST_auth\/logout$/,
  'POST_auth/register': /^POST_auth\/register$/,
  'GET_invitation/{code}': /^GET_invitation\/[a-zA-Z0-9-_]+$/,
  'POST_auth/reset-password/{token}/':
    /^POST_auth\/reset-password\/[a-zA-Z0-9-_]+\/$/,
  'GET_auth/verify-email/{token}/':
    /^GET_auth\/verify-email\/[a-zA-Z0-9-_]+\/$/,
  'POST_automation-push': /^POST_automation-push$/,
  'GET_automation/': /^GET_automation\/$/,
  'POST_automation/': /^POST_automation\/$/,
  POST_optimize: /^POST_optimize$/,
  'GET_automation/{id}/': /^GET_automation\/\d+\/$/,
  'PATCH_automation/{id}/': /^PATCH_automation\/\d+\/$/,
  'POST_google-search-console/test-indexing/{id}/':
    /^POST_google-search-console\/test-indexing\/\d+\/$/,
  'GET_billing/plans-tiers': /^GET_billing\/plans-tiers$/,
  'POST_billing/check-payment': /^POST_billing\/check-payment$/,
  'POST_billing/portal-link': /^POST_billing\/portal-link$/,
  'POST_billing/portal-link-checkout': /^POST_billing\/portal-link-checkout$/,
  'POST_billing/change-plan': /^POST_billing\/change-plan$/,
  'POST_billing/cancel-plan': /^POST_billing\/cancel-plan$/,
  'POST_csv-batch-upload/': /^POST_csv-batch-upload\/$/,
  'GET_csvfile/': /^GET_csvfile\/$/,
  'POST_csvfile/': /^POST_csvfile\/$/,
  'GET_csvfile/{id}/': /^GET_csvfile\/\d+\/$/,
  'DELETE_csvfile/{id}/': /^DELETE_csvfile\/\d+\/$/,
  'GET_document/': /^GET_document\/$/,
  'POST_document/': /^POST_document\/$/,
  'GET_document/{uuid}/': /^GET_document\/[a-zA-Z0-9-_]+\/$/,
  'PATCH_document/{uuid}/': /^PATCH_document\/[a-zA-Z0-9-_]+\/$/,
  'DELETE_document/{uuid}/': /^DELETE_document\/[a-zA-Z0-9-_]+\/$/,
  'GET_get-desired-seo': /^GET_get-desired-seo$/,
  'GET_greg-chat/': /^GET_greg-chat\/$/,
  'POST_greg-chat/': /^POST_greg-chat\/$/,
  'GET_greg-chat/{id}/': /^GET_greg-chat\/\d+\/$/,
  'GET_greg-completion/': /^GET_greg-completion\/$/,
  'POST_greg-completion/': /^POST_greg-completion\/$/,
  'GET_greg-completion/{id}/': /^GET_greg-completion\/\d+\/$/,
  'GET_greg-product/': /^GET_greg-product\/$/,
  'POST_greg-product/': /^POST_greg-product\/$/,
  'GET_greg-product/{id}/': /^GET_greg-product\/\d+\/$/,
  'POST_persona-custom/': /^POST_persona-custom\/$/,
  'PUT_persona-custom/{id}/': /^PUT_persona-custom\/\d+\/$/,
  'PATCH_persona-custom/{id}/': /^PATCH_persona-custom\/\d+\/$/,
  'GET_persona-filetraining-by-persona/{id}/':
    /^GET_persona-filetraining-by-persona\/\d+\/$/,
  'GET_persona-filetraining/': /^GET_persona-filetraining\/$/,
  'POST_persona-filetraining/': /^POST_persona-filetraining\/$/,
  'GET_persona-filetraining/{id}/': /^GET_persona-filetraining\/\d+\/$/,
  'DELETE_persona-filetraining/{id}/': /^DELETE_persona-filetraining\/\d+\/$/,
  'POST_persona-standard/': /^POST_persona-standard\/$/,
  'PUT_persona-standard/{id}/': /^PUT_persona-standard\/\d+\/$/,
  'PATCH_persona-standard/{id}/': /^PATCH_persona-standard\/\d+\/$/,
  'GET_persona/': /^GET_persona\/$/,
  'GET_persona/{id}/': /^GET_persona\/\d+\/$/,
  'DELETE_persona/{id}/': /^DELETE_persona\/\d+\/$/,
  'POST_phrase/': /^POST_phrase\/$/,
  'GET_phrase/{id}': /^GET_phrase\/\d+$/,
  'GET_prompt-template/custom-template/':
    /^GET_prompt-template\/custom-template\/$/,
  'POST_prompt-template/custom-template/':
    /^POST_prompt-template\/custom-template\/$/,
  'GET_prompt-template/custom-template/{id}/':
    /^GET_prompt-template\/custom-template\/\d+\/$/,
  'PATCH_prompt-template/custom-template/{id}/':
    /^PATCH_prompt-template\/custom-template\/\d+\/$/,
  'DELETE_prompt-template/custom-template/{id}/':
    /^DELETE_prompt-template\/custom-template\/\d+\/$/,
  'GET_prompt_templates/': /^GET_prompt_templates\/$/,
  'DELETE_prompt_templates/choice/{id}/':
    /^DELETE_prompt_templates\/choice\/\d+\/$/,
  'GET_prompt_templates/{id}/': /^GET_prompt_templates\/\d+\/$/,
  'GET_prompt_templates/{id}/history/':
    /^GET_prompt_templates\/[a-zA-Z0-9-_]+\/history\/(\?page=\d+)?(&page_size=\d+)?$/,
  'GET_public-shop/': /^GET_public-shop\/(\?page=\d+)?(&page_size=\d+)?$/,
  'POST_public-shop/': /^POST_public-shop\/$/,
  'GET_public-shop/{id}/': /^GET_public-shop\/\d+\/$/,
  'GET_google-search-console/authorization/':
    /^GET_google-search-console\/authorization\/(\?(redirect_uri=[a-zA-Z0-9-._~:\/?#[\]@!%$&'()*+,;=]+|property_id=[a-zA-Z0-9-._~:\/?#[\]@!%$&'()*+,;=]+)|(\?(redirect_uri=[a-zA-Z0-9-._~%:\/?#[\]@!$&'()*+,;=]+)?(&property_id=[a-zA-Z0-9-._~:\/?#[\]@!%$&'()*+,;=]+)?))(&public_shop_id=\d+)?$/,
  'PATCH_request-row-to-openai/{id}': /^PATCH_request-row-to-openai\/\d+$/,
  'POST_team_member/add': /^POST_team_member\/add$/,
  'GET_team_member/members': /^GET_team_member\/members$/,
  'PATCH_team_member/modify/{id}/': /^PATCH_team_member\/modify\/\d+\/$/,
  'POST_team_member/register': /^POST_team_member\/register$/,
  'POST_team_member/remove': /^POST_team_member\/remove$/,
  'GET_user-avatar-decode/': /^GET_user-avatar-decode\/$/,
  'GET_user-details/': /^GET_user-details\/$/,
  'POST_user-report-bug/': /^POST_user-report-bug\/$/,
  'POST_user-request-plan-upgrade/': /^POST_user-request-plan-upgrade\/$/,
  'GET_user/': /^GET_user\/$/,
  'PATCH_user/': /^PATCH_user\/$/,
  'GET_workflows-batchrows/{id}':
    /^GET_workflows-batchrows\/\d+(\?page=\d+)?(&page_size=\d+)?$/,
  'PATCH_workflows-batchrows/{id}': /^PATCH_workflows-batchrows\/\d+$/,
  'PATCH_request-row/{id}': /^PATCH_request-row\/\d+$/,
  'POST_automation-rerun-post/{id}': /^POST_automation-rerun-post\/\d+$/,
  'GET_automation-rerun-get/{id}': /^GET_automation-rerun-get\/\d+$/,
  'POST_write/': /^POST_write\/$/,
  'GET_autopilot-job-csv/{id}': /^GET_autopilot-job-csv\/\d+$/,
  'GET_autopilot-job/': /^GET_autopilot-job\/$/,
  'POST_autopilot-job/': /^POST_autopilot-job\/$/,
  'GET_autopilot-job/{id}/': /^GET_autopilot-job\/\d+\/$/,
  'PATCH_autopilot-job/{id}/': /^PATCH_autopilot-job\/\d+\/$/,
  'POST_autopilot-push': /^POST_autopilot-push$/,
  'PATCH_autopilot-reoptimize/{id}': /^PATCH_autopilot-reoptimize\/\d+$/,
  'POST_autopilot-reoptimize-post/{id}':
    /^POST_autopilot-reoptimize-post\/\d+$/,
  'GET_autopilot-reoptimize-get/{id}': /^GET_autopilot-reoptimize-get\/\d+$/,
  'PATCH_automation-reoptimize/{id}': /^PATCH_automation-reoptimize\/\d+$/,
  'POST_automation-reoptimize-post/{id}':
    /^POST_automation-reoptimize-post\/\d+$/,
  'GET_automation-reoptimize-get/{id}': /^GET_automation-reoptimize-get\/\d+$/,
  'PATCH_autopilot-rerun/{id}': /^PATCH_autopilot-rerun\/\d+$/,
  'POST_autopilot-rerun-post/{id}': /^POST_autopilot-rerun-post\/\d+$/,
  'GET_autopilot-rerun-get/{id}': /^GET_autopilot-rerun-get\/\d+$/,
  'PATCH_autopilot-review-admin/{id}': /^PATCH_autopilot-review-admin\/\d+$/,
  'GET_autopilot-rows/{id}':
    /^GET_autopilot-rows\/\d+(\?page=\d+)?(&page_size=\d+)?$/,
  'PATCH_autopilot-rows/{id}': /^PATCH_autopilot-rows\/\d+$/,
  'GET_autopilot/{id}/': /^GET_autopilot\/\d+\/$/,
  'PATCH_autopilot/{id}/': /^PATCH_autopilot\/\d+\/$/,
  'PATCH_autopilot-job/{id}/': /^PATCH_autopilot-job\/\d+\/$/,
  'GET_pdp/': /^GET_pdp\/$/,
  'GET_api-integration/categories/{id}/':
    /^GET_api-integration\/categories\/\d+\/(\?page=\d+)?(&page_size=\d+)?$/,
  'GET_api-integration/pull/{id}/':
    /^GET_api-integration\/pull\/\d+\/(\?page=\d+)?(&page_size=\d+)?$/,
  'GET_pdf/{id}/': /^GET_pdf\/\d+\/$/,
  POST_pdf: /^POST_pdf$/,
  'GET_activity-log/{type}':
    /^GET_activity-log\/[a-zA-Z]+(\?page=\d+)?(&page_size=\d+)?(&public_shop_id=\d+)?$/,
  'POST_undo-content-update/{id}': /^POST_undo-content-update\/\d+$/,
  'GET_performance/shop/{id}': /^GET_performance\/shop\/\d+$/,
  'GET_performance/product/{id}': /^GET_performance\/product\/\d+$/,
  'GET_performance/push/{id}': /^GET_performance\/push\/\d+$/,
  'GET_pushed-products/{id}':
    /^GET_pushed-products\/\d+(\?page=\d+)?(&page_size=\d+)?$/,
  'GET_product-pulls/{id}':
    /^GET_product-pulls\/\d+(\?page=\d+)?(&page_size=\d+)?$/,
  'GET_autopilot-rescore-get/{id}': /^GET_autopilot-rescore-get\/\d+$/,
  'POST_autopilot-rescore-post/{id}': /^POST_autopilot-rescore-post\/\d+$/,
  'GET_onboarding-job/{id}': /^GET_onboarding-job\/\d+$/,
  'POST_onboarding-rerun-post/{id}': /^POST_onboarding-rerun-post\/\d+$/,
  'GET_onboarding-rerun-get/{id}': /^GET_onboarding-rerun-get\/\d+$/,
};

const searchKey = (requestedRoute) => {
  let keys = 'default';
  try {
    for (const key in routes) {
      if (Object.hasOwnProperty.call(routes, key)) {
        const element = routes[key];
        if (element.test(requestedRoute)) {
          keys = key;
          break;
        }
      }
    }
    return keys;
  } catch (error) {
    return keys;
  }
};

export default searchKey;
