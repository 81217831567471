import { GREY, COMMON, PRIMARY, INFO, GRADIENTS } from '@theme/modes/common';
import { DARK } from '@theme/shadows';

const styles = {
  ...COMMON,
  primary: {
    ...PRIMARY,
    main: '#7e8cdd',
    dark: '#1E0532',
    darker: '#000000',
  },
  info: { ...INFO, main: '#573a90' },
  gradients: {
    ...GRADIENTS,
    cardPrice: 'linear-gradient(90deg, #e1d8f6 0%, #e1d8f6 86%)',
    cardChooseButton: 'linear-gradient(90deg, #F95D75 -78.84%, #573A90 100%)',
    connectButton: 'linear-gradient(to right, #3DC28A, #03A6C9)',
    authEkomTitle: `linear-gradient(92deg, #7E8CDD 30%, #e1d8f6 100%)`,
    stepAuthItem: 'linear-gradient(145deg, #453174 0%, #e1d8f6 100%)',
    icons: {
      primary: {
        from: '#7E8CDD',
        to: '#412B53',
      },
      success: {
        from: '#3DC28A',
        to: '#03A6C9',
      },
    },
  },
  menuIcon: '#7E8CDD',
  text: {
    primary: '#ffffff',
    secondary: '#c8c0dc',
    disabled: GREY[600],
    neutral: GREY[0],
    alternative: '#B3BCF2',
    contrastText: '#573a90',
    alternativeContrastText: '#1E0532',
    link: '#c8c0dc',
    customTitle: '#C2B9D8',
  },
  disableButtonText: GREY[500],
  sidebar: {
    background: 'transparent',
    border: '#887AAF',
    borderDivider: '#c5cbd3',
  },
  auth: {
    input: '#382759',
  },
  background: {
    paper: '#51328d',
    default: '#51328d',
    neutral: GREY[500_16],
  },
  table: {
    primary: '#907EB8',
    stickyHeader: PRIMARY.darker,
    alternative: '#B3BCF2',
  },
  shadows: DARK,
  action: { active: GREY[500], ...COMMON.action },
  cards: {
    shadows: {
      primary: '#8c83c340',
      secondary: '#8c83c340',
    },
    border: GREY[300],
    background: '#FFFFFF',
    planRailBackground: '#EEEEEE',
    subscriptionBackground: '#371f4c66',
    cardPrice: '#AA7FEE',
    checkGradient: {
      from: '#583B90',
      to: '#B14E82',
    },
    integrationBackground: '#412B53',
    integrationText: '#000',
    settings: {
      background: COMMON['secondary']['light'],
      shadow: 'none',
    },
    members: {
      background: '#51328d',
      shadow: 'none',
    },
    optimize: {
      notPushed: '#7E8CDD',
      pushed: '#3DC28A',
    },
    plan: {
      essential: {
        background: 'linear-gradient(0deg, #826dac 0%, #826dac 100%)',
        text: 'linear-gradient(90deg, #e1d8f6 0%, #e1d8f6 86%)',
        border: '#7e8cdd',
      },
      growth: {
        background: '#826dac',
        text: 'linear-gradient(90deg, #e1d8f6 0%, #e1d8f6 86%)',
        border: '#3C3C3C',
      },
      enterprise: {
        background: 'linear-gradient(0deg, #826dac 20%, #826dac 100%)',
        text: 'linear-gradient(90deg, #000 0%, #000 86%)',
        border: '#4d4a86',
      },
    },
  },
  switch: { active: '#7e8cdd', inactive: '#4d4a86' },
  dropdowns: {
    secondary: '#573A90',
    border: '#FFFFFF',
    darker: '#1e0532',
    parentContainer: '#3a1f50',
    childContainer: '#4D3974',
    singleOptionBackground: '#3a1f50',
    multiOptionBackground: '#3a1f50',
    checkBox: '#3dc292',
    member: {
      backgroundSelector: '#fff',
      backgroundOptions: '#fff',
      border: '#1c1c1c',
    },
  },
  loader: {
    spinner: {
      border: '#7e8cdd',
      shadow: '#492787',
      shadowContrast: '#161a4280',
      background: '#8c83c39c',
    },
  },
  various: {
    buttonOptions: '#FF4E8D',
    matchText: '#C76BFF',
    newText: '#3DC292',
    dashboardsCardsText: '#A6B2F5',
    softIcons: '#FFF',
    divider: '#FFF',
    errorPlaceholder: '#C94747',
    errorMessages: '#C94747',
    badgeCounter: '#D42C9B',
    emailButton: '#573A90',
  },
  icon: {
    primary: '#94A1E9',
  },
  dashboard: {
    activityLog: {
      borderDivider: '#573A90',
      itemBackground: '#573A90',
    },
    graphic: {
      subtitle: '#94A1E9',
      containerBg: '#331B48',
      gridColor: '#503466',
      optimized: '#943432',
      nonOptimized: '#7E4591',
      sessions: '#C9412633',
      conversions: '#888888',
      filter: {
        optionSelected: '#B982FF',
        option: '#49345c',
      },
    },
  },
  keywords: {
    input: '#7E6AA6',
    button: '#A599BC',
    buttonModal: '#573A90',
  },
  checkBox: {
    border: '#C5CBD3',
    background: '#3dc292',
    icon: '#000',
  },
  buttons: {
    background: '#94A1E9',
    text: '#1E0532',
    backgroundSecondary: '#94A1E9',
    borderSecondary: '#94A1E9',
  },
  logoutButton: {
    background: COMMON.common.white,
    text: '#000',
  },
  GSCButton: {
    backgroundCard: '#E2E8F0AD',
    backgroundButton: '#94A1E9',
  },
  stepper: {
    disabled: '#ECECEC',
    dividerBorder: GREY[300],
  },
  permissionsDenied: {
    gradient: {
      from: '#FA5C73',
      to: '#7D58C7',
    },
    denied: '#C64865',
  },
  successsView: {
    borderContainer: '#C8C0DC4D',
    iconBorder: '#D6CDEB',
    backgroundContainer: '#5B4382',
  },
  cmsIntregrations: {
    backgroundLogo: 'transparent',
    borderLogo: '#AEA6C4',
    borderSelectedLogo: '#1E0532',
    backgroundSelectedLogo: '#8C83C333',
  },
  dialog: {
    background: 'none',
    backgroundContainer: '#573a90',
    resultBg: '#573a90',
  },
  snackBar: {
    background: {
      success: '#51328d',
      error: '#51328d',
    },
  },
  subscription: {
    alternative: '#B3BCF2',
  },
  brandGuideline: {
    background: '#573A90',
  },
  slider: {
    background: '#7E8CDD',
    border: '#FA5C73',
  },
  textEditor: {
    outputBackground: '#FFFFFF1A',
    delimiter: '3DC292',
    attribute: '95A4FF',
    attributeValue: 'DA4398',
  },

  cmsIcons: {
    ...COMMON.cmsIcons,
    woocommerce: {
      ...COMMON.cmsIcons.woocommerce,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#34232B',
      },
    },
    shopify: {
      ...COMMON.cmsIcons.shopify,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#34232B',
      },
    },
  },
  moderationCard: {
    iconBackground: '#3D236F',
    containerBackground: '#3D294E',
    iconContainerBackground: '#645471',
    status: {
      success: '#3DC292',
      error: '#FF4F4F',
      warning: '#FF994F',
    },
  },
  onboarding: {
    containerBg: '#331b48',
    automaticCardBg: '#6d666675',
    cardBg: '#3e2752',
    cardBorder: '#634286',
    progressBg: '#B982FF',
    progressTransparency: '#cdd3f2',
    buttonBorder: '#b982ff',
    shopsContainerBg: '#764ea3',
    buttonText: '#220639',
    rulesContainerBg: '#6d666675',
    rulesContainerSettingsBg: '#6d666675',
    buttonDisabled: '#4e306d',
    disabledCardBg: '#3e2752',
    disabledCardBorder: '#3e2752',
    disabledCardText: '#fff',
    gscCardBg: '#6d666675',
  },
  tinymce: {
    background: '#573a90',
    backgroundHeader: '#fff',
    html: '#3DC292',
    variables: '#95A4FF',
    values: '#DA4398',
  },
};
export default styles;
