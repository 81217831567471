// @import Images
import backgroundImgTwilight1 from '@assets/images/background1-twilight.svg';
import openSibarLogoDark from '@assets/images/open-sidebar-logo-dark.svg';
import openSidebarLogoLight from '@assets/images/open-sidebar-logo-light.svg';
import closedSidebarLogoLight from '@assets/images/closed-sidebar-logo-light.png';
import closedSidebarLogoDark from '@assets/images/closed-sidebar-logo-dark.png';
import magentoFullLogo from '@assets/images/cms-logos/magento.png';
import akeneoFullLogo from '@assets/images/cms-logos/akeneo.png';
import bigcommerceFullLogo from '@assets/images/cms-logos/bigcommerce.avif';
import webflowFullLogo from '@assets/images/cms-logos/webflow.svg';
import salesforceFullLogo from '@assets/images/cms-logos/salesforce.png';
import shopifyFullLogo from '@assets/images/cms-logos/shopify.png';
import woocommerceFullLogo from '@assets/images/cms-logos/woocommerce.png';
import magnoliaFullLogo from '@assets/images/cms-logos/magnolia.png';
import magentoFullLogoLight from '@assets/images/cms-logos/magento-light.png';
import akeneoFullLogoLight from '@assets/images/cms-logos/akeneo-light.png';
import shopifyFullLogoLight from '@assets/images/cms-logos/shopify-light.png';
import woocommerceFullLogoLight from '@assets/images/cms-logos/woocommerce-light.png';
import bigcommerceFullLogoLight from '@assets/images/cms-logos/bigcommerce-light.png';
import webflowFullLogoLight from '@assets/images/cms-logos/webflow-light.svg';

const subDomain = window.location.hostname.split('.')[0];

const tenant = !['web', 'staging', 'localhost'].includes(subDomain)
  ? subDomain
  : null;

if (tenant) {
  const newFaviconIco = `https://web.ekom.ai/tenant-assets/${tenant}/images/favicon.ico`;
  const link = document.querySelector("link[rel~='icon']");
  const appleLink = document.querySelector("link[rel~='apple-touch-icon']");

  link.href = newFaviconIco;
  appleLink.href = newFaviconIco;
}

export const hexToRgbaWithOpacity = (hex, opacity) => {
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (h) {
        return h + h;
      })
      .join('');
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
};

export const createFormData = (data) => {
  const values = { ...data };
  if (values.template_id === '953c21d5-6f60-4b36-9982-a7cae740fdb8') {
    values['{{ tp_type_of_answer }}'] = values.tp_type_of_answer;
    values['{{ tp_tone }}'] = values.tp_tone;

    delete values.tp_type_of_answer;
    delete values.tp_tone;
  }
  const formData = new FormData();
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      formData.append(key, values[key]);
    }
  }
  return formData;
};

export const deleteNullValues = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
};

export const dataURLToBlob = (dataUrl) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const getFirstBackgroundImageByMode = (themeMode) => {
  const backgroundImages = {
    twilight: backgroundImgTwilight1,
  };
  return backgroundImages[themeMode];
};

export const getLayoutBackgroundImage = (themeMode, bgImageNumber) => {
  const backgroundImages = {
    twilight: [backgroundImgTwilight1],
  };

  return backgroundImages[themeMode]
    ? backgroundImages[themeMode][bgImageNumber - 1]
    : null;
};

export const getSideBarLogoImage = (sideBarOpen, themeMode) => {
  if (tenant) {
    return `https://web.ekom.ai/tenant-assets/${tenant}/images/logo`;
  }

  const openSidebarLogos = {
    dark: openSibarLogoDark,
    twilight: openSibarLogoDark,
    light: openSidebarLogoLight,
  };
  const closedSidebarLogos = {
    dark: closedSidebarLogoDark,
    twilight: closedSidebarLogoDark,
    light: closedSidebarLogoLight,
  };

  return sideBarOpen
    ? openSidebarLogos[themeMode]
    : closedSidebarLogos[themeMode];
};

export const getUpdatedAutomation = (processingAutomations, dbAutomations) => {
  const dbCompletedAutomationsIds = dbAutomations
    .filter(
      (automation) =>
        automation.state === 'completed' || automation.state === 'sent'
    )
    .map((dbAutomation) => dbAutomation.id);

  const updatedAutomation = processingAutomations.find((a) =>
    dbCompletedAutomationsIds.includes(a.id)
  );
  return updatedAutomation;
};

export const getNewIntegrationsData = (theme = 'light') => {
  const isLight = theme === 'light';
  return [
    {
      src: isLight ? shopifyFullLogoLight : shopifyFullLogo,
      width: '128px',
      height: isLight ? '75px' : '64px',
      alt: 'Shopify Full Logo',
      value: 'shopify',
    },
    {
      src: isLight ? woocommerceFullLogoLight : woocommerceFullLogo,
      width: '74px',
      height: '40px',
      alt: 'Woocommerce Full Logo',
      value: 'woocommerce',
    },
    {
      src: isLight ? magentoFullLogoLight : magentoFullLogo,
      width: '128px',
      height: '38px',
      alt: 'Magento Full Logo',
      value: 'magento',
    },
    {
      src: salesforceFullLogo,
      width: '74px',
      height: '56px',
      alt: 'Salesforce Full Logo',
      value: 'salesforce',
      disabled: true,
    },
    {
      src: magnoliaFullLogo,
      width: '138px',
      height: '38px',
      alt: 'Magnolia Full Logo',
      value: 'magnolia',
      disabled: true,
    },
    {
      src: isLight ? akeneoFullLogoLight : akeneoFullLogo,
      width: '128px',
      height: '28px',
      alt: 'Akeneo Full Logo',
      value: 'akeneo',
    },
    {
      src: isLight ? bigcommerceFullLogoLight : bigcommerceFullLogo,
      width: '128px',
      height: '28px',
      alt: 'Bigcommerce Full Logo',
      value: 'bigcommerce',
    },
    {
      src: isLight ? webflowFullLogoLight : webflowFullLogo,
      width: '128px',
      height: '28px',
      alt: 'Webflow Full Logo',
      value: 'webflow',
    },
  ];
};

export const calculatePercentageDifference = (previousValue, currentValue) => {
  const difference = currentValue - previousValue;
  const percentageDifference = (difference / previousValue) * 100;
  return percentageDifference.toFixed(2);
};

export const transformToK = (number) => {
  const units = ['', 'K', 'M', 'B', 'T'];
  let transformedNumber = number;

  if (number >= 1000) {
    const unitIndex = Math.floor(Math.log10(number) / 3);
    let scaledNumber = number / Math.pow(1000, unitIndex);
    if (!Number.isInteger(scaledNumber)) scaledNumber = scaledNumber.toFixed(1);
    transformedNumber = `${scaledNumber}${units[unitIndex]}`;
  }

  return transformedNumber;
};

export const calculateArraySum = (array) => {
  const totalSum = array.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return totalSum;
};

export function calculateArrayAverage(array) {
  const sum = array.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const average = sum / array.length;
  return !average ? 0 : average.toFixed(1);
}

export const calculateArrayAveragePercentage = (array) => {
  const sum = array.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const averagePercentage = (sum / array.length) * 100;
  return !averagePercentage ? 0 : averagePercentage.toFixed(1);
};

export const convertSecondsToString = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursString = hours.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = remainingSeconds.toString().padStart(2, '0');

  return `${hoursString}:${minutesString}:${secondsString}`;
};

export const hasFormValueChanged = (firstObject, secondObject) => {
  const stringKeysToCompare = Object.keys(firstObject);
  const stringKeysChanged = stringKeysToCompare.some(
    (key) => firstObject[key] !== secondObject[key]
  );
  return stringKeysChanged;
};

export const CSVExport = async (rawCsvData, prefixFileName) => {
  if (rawCsvData) {
    const formattedHeaders = rawCsvData
      .slice(0, rawCsvData.indexOf('\n'))
      .split(',')
      .map((item) => item.replaceAll('_', ' ').toUpperCase());
    const csvData = `${formattedHeaders}\n${rawCsvData.slice(
      rawCsvData.indexOf('\n') + 1
    )}`;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.setAttribute('download', prefixFileName);
    downloadLink.click();
  }
};

export const formatBytesSize = (size) => {
  if (size < 1024) {
    return size + ' B';
  } else if (size < 1024 * 1024) {
    return Math.ceil(size / 1024) + ' KB';
  } else if (size < 1024 * 1024 * 1024) {
    return Math.ceil(size / (1024 * 1024)) + ' MB';
  } else {
    return Math.ceil(size / (1024 * 1024 * 1024)) + ' GB';
  }
};

export const getIconColorAttributes = ({ icon, customIconColor, theme }) => {
  return icon?.colorAttributes?.reduce((acc, item) => {
    acc[item] = customIconColor || theme.palette.text.primary;
    return acc;
  }, {});
};

function stringifyObject(obj) {
  return JSON.stringify(obj);
}

export const avoidDuplicatesObjectsFromArray = (array) => {
  const stringifyArray = array.map(stringifyObject);
  const stringifySet = new Set(stringifyArray);
  return Array.from(stringifySet).map(JSON.parse);
};
