import lightMode from '@theme/modes/light';
import twilightMode from '@theme/modes/twilight';
import darkMode from '@theme/modes/dark';

const palette = {
  light: lightMode,
  dark: darkMode,
  twilight: twilightMode,
};

export default palette;
