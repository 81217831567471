// @import Dependencies
import { createContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// @import Reducers
import { getAuthUserDetails, getTeamMembers } from '@redux/slices/user';
import { getCmsIntegrations } from '@redux/slices/integration';
import {
  getAutopilotJobs,
  getListAutopilotJobSettings,
} from '@redux/slices/autopilot';
import { getDashboardData } from '@redux/slices/dashboard';

// @import Paths
import { authPaths, guestPaths } from '@routes/paths';

const initialState = {};

const UserContext = createContext(initialState);

UserProvider.propTypes = {
  children: PropTypes.node,
};

function UserProvider({ children }) {
  const dispatch = useDispatch();
  const location = window.location.pathname;
  const accessToken = useSelector((state) => state.user?.accessToken);
  const token = localStorage.getItem('accessToken') || accessToken;
  const isEmailVerified = localStorage.getItem('emailVerified');
  const avoidRequestsPaths = [
    guestPaths.signUpIntegration,
    guestPaths.loginEmbedded,
    guestPaths.loginIntegration,
  ];
  const generalRender = useRef(true);
  const pathConditions = !avoidRequestsPaths.some((path) => location === path);

  useEffect(() => {
    if (generalRender.current && token && isEmailVerified && pathConditions) {
      generalRender.current = false;
      if (location !== authPaths.billingRedirection)
        dispatch(getAuthUserDetails({ token }));
      dispatch(getCmsIntegrations());
      dispatch(getAutopilotJobs());
      dispatch(getListAutopilotJobSettings());
      dispatch(getDashboardData());
    }
  }, [token]);

  useEffect(() => {
    if (token && isEmailVerified && pathConditions) dispatch(getTeamMembers());
  }, [token]);

  return <UserContext.Provider value={{}}>{children}</UserContext.Provider>;
}

export { UserProvider, UserContext };
