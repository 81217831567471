// @import Dependencies
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// @import Reducers
import userReducer from '@redux/slices/user';
import navigationReducer from '@redux/slices/navigation';
import authReducer from '@redux/slices/auth';
import dashboardReducer from '@redux/slices/dashboard';
import integrationReducer from '@redux/slices/integration';
import automationReducer from '@redux/slices/automation';
import autopilotReducer from '@redux/slices/autopilot';
import sharedJobsReducer from '@redux/slices/sharedJobs';

const combinedReducer = combineReducers({
  user: userReducer,
  navigation: navigationReducer,
  auth: authReducer,
  integration: integrationReducer,
  automation: automationReducer,
  autopilot: autopilotReducer,
  dashboard: dashboardReducer,
  sharedJobs: sharedJobsReducer,
});

export default configureStore({
  reducer: combinedReducer,
});
