import { GREY, COMMON, PRIMARY, GRADIENTS } from '@theme/modes/common';
import { LIGHT } from '@theme/shadows';

const styles = {
  ...COMMON,
  mode: 'light',
  text: {
    primary: GREY[800],
    secondary: '#000',
    disabled: GREY[500],
    neutral: GREY[800],
    alternative: '#CCA377',
    contrastText: '#fff',
    alternativeContrastText: '#000',
    link: '#000',
    customTitle: '#5B5666',
  },
  disableButtonText: 'rgba(131, 131, 131, 1)',
  sidebar: {
    background: '#C5CBD366',
    border: '#C5CBD3',
    borderDivider: '#C5CBD3',
  },
  auth: {
    input: 'rgba(20, 20, 20, 0.09)',
  },
  background: { paper: '#FDFAF7', default: '#FDFAF7', neutral: GREY[100] },
  table: {
    primary: '#FDFAF7',
    stickyHeader: '#FDFAF7',
    alternative: '#93464E',
  },
  shadows: LIGHT,
  action: { active: GREY[600], ...COMMON.action },
  cards: {
    shadows: {
      primary: 'transparent',
      secondary: 'transparent',
    },
    border: GREY[500],
    planRailBackground: '#CCA377',
    subscriptionBackground: '#FFFFFF',
    cardPrice: '#CCA377',
    checkGradient: {
      from: '#CCA377',
      to: '#CCA377',
    },
    integrationBackground: '#f5f3f0',
    integrationText: '#000',
    settings: {
      background: '#FFFFFF',
      shadow: LIGHT[6],
    },
    members: {
      background: '#FFFFFF',
      shadow: LIGHT[6],
    },
    optimize: {
      notPushed: '#942430',
      pushed: '#CBA376',
    },
    plan: {
      essential: {
        background: 'linear-gradient(0deg, transparent 0%, transparent 100%)',
        text: GRADIENTS.cardPrice,
        border: '#CCA377',
      },
      growth: {
        background: 'transparent',
        text: GRADIENTS.cardPrice,
        border: '#3C3C3C',
      },
      enterprise: {
        background: 'linear-gradient(0deg, transparent 20%, transparent 100%)',
        text: 'linear-gradient(90deg, #000 0%, #000 86%)',
        border: '#942430',
      },
    },
  },
  switch: { active: '#CCA377', inactive: '#9E7E5B82' },
  dropdowns: {
    secondary: '#fff',
    border: '#000',
    darker: '#000',
    parentContainer: '#fff',
    childContainer: '#f5eced',
    singleOptionBackground: '#fff',
    multiOptionBackground: '#fff',
    checkBox: '#CCA377',
    member: {
      backgroundSelector: '#fff',
      backgroundOptions: '#fff',
      border: '#1c1c1c',
    },
  },
  loader: {
    spinner: {
      border: PRIMARY['main'],
      shadow: PRIMARY['dark'],
      shadowContrast: '#fff',
      background: '#ffffffa3',
    },
  },
  various: {
    buttonOptions: '#FF4E8D',
    matchText: '#CBA376',
    newText: '#317878',
    dashboardsCardsText: '#000',
    softIcons: GREY[600],
    divider: '#DFDFDF',
    errorPlaceholder: '#F10000',
    errorMessages: '#F10000',
    badgeCounter: '#D42C9B',
    emailButton: '#e0dddc',
  },
  icon: {
    primary: '#CBA376',
  },
  dashboard: {
    activityLog: {
      borderDivider: '#CBA376',
      itemBackground: '#f0f0f0',
    },
    graphic: {
      subtitle: '#000',
      containerBg: '#fff',
      gridColor: '#CBA37626',
      optimized: '#943432',
      nonOptimized: '#434C4C',
      sessions: '#C9412633',
      conversions: '#888888',
      filter: {
        optionSelected: '#CBA376',
        option: '#fff',
      },
    },
  },
  keywords: {
    input: '#ece9eb',
    button: '#C1B6D699',
    buttonModal: '#DEDEFF1C',
  },
  checkBox: {
    border: '#C5CBD3',
    background: '#CBA376',
    icon: '#000',
  },
  buttons: {
    background: '#CBA376',
    text: '#000',
    backgroundSecondary: '#942430',
    borderSecondary: '#942430',
  },
  logoutButton: {
    background: '#FDFAF7',
    text: '#000',
  },
  GSCButton: {
    backgroundCard: '#FDFAF7',
    backgroundButton: '#FDFAF7',
  },
  stepper: {
    disabled: '#ece9eb',
    dividerBorder: '#942430',
  },
  permissionsDenied: {
    gradient: {
      from: '#942430',
      to: '#CBA376',
    },
    denied: '#C64865',
  },
  successsView: {
    borderContainer: '#FFFFFF4D',
    iconBorder: '#000',
    backgroundContainer: '#FFFFFF4D',
  },
  cmsIntregrations: {
    backgroundLogo: 'transparent',
    borderLogo: '#fff',
    borderSelectedLogo: '#000',
    backgroundSelectedLogo: '#FDFAF7',
  },
  dialog: {
    background: '#dce0e585',
    backgroundContainer: '#fff',
    resultBg: '#FDFAF7',
  },
  snackBar: {
    background: {
      success: '#fff',
      error: '#fff',
    },
  },
  subscription: {
    alternative: '#CBA376',
  },
  brandGuideline: {
    background: '#FFFFFF',
  },
  slider: {
    background: '#CBA376',
    border: '#942430',
  },
  textEditor: {
    outputBackground: '#e2d1d5',
    delimiter: 'A8753C',
    attribute: '317878',
    attributeValue: 'AD5555',
  },
  cmsIcons: COMMON.cmsIcons,
  moderationCard: {
    iconBackground: '#e9e6e3',
    containerBackground: '#e9e6e3',
    iconContainerBackground: '#FDFAF7',
    status: {
      success: '#3DC292',
      error: '#FF4F4F',
      warning: '#FF994F',
    },
  },
  onboarding: {
    containerBg: '#fff',
    automaticCardBg: '#ece9eb',
    cardBg: '#952431',
    cardBorder: '#952431',
    progressBg: '#CBA376',
    progressTransparency: '#cccccc',
    buttonBorder: '#CBA376',
    shopsContainerBg: '#ece9eb',
    buttonText: '#000',
    rulesContainerBg: '#ece9eb',
    rulesContainerSettingsBg: '#ece9eb',
    buttonDisabled: '#CBA37626',
    disabledCardBg: '#f4e9ea',
    disabledCardBorder: '#f4e9ea',
    disabledCardText: '#000',
    gscCardBg: '#ece9eb',
  },
  tinymce: {
    background: '#ece9eb',
    backgroundHeader: '#e2d1d5',
    html: '#A8753C',
    variables: '#317878',
    values: '#AD5555',
  },
};
export default styles;
