// @import Dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';

// @import Services
import {
  getDashboardDataAPI,
  addPublicShopAPI,
  getDashboardDataByIdAPI,
  getGoogleSearchConsoleAuthAPI,
  getDashboardOverviewDataByIdAPI,
  getDashboardProductPerformanceAPI,
  getDashboardOptimizationPerformanceAPI,
  getDashboardOptimizationProductsAPI,
} from '@api/dashboard';

// @import Reducers
import { setOnboardingData } from '@redux/slices/navigation';

export const initialState = {
  loading: false,
  loadingPublicShop: false,
  loadingGoogleAuth: false,
  loadingOverviewData: false,
  loadingOptimizationPerformance: false,
  loadingIndividualPerformance: false,
  targetedOptimizationTable: { rowsRender: 10 },
  currentShopData: null,
  optimizationPerformanceData: null,
  productsPagination: {
    currentPage: 0,
    hasMore: false,
  },
  shopsPagination: {
    currentPage: 0,
    hasMore: false,
    list: [],
  },
};
// ------------------THUNKS----------------
export const getDashboardData = createAsyncThunk(
  'cms/getDashboardData',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getDashboardDataAPI(body);
    if (isSuccessful) {
      if (!body?.page || body?.page === 1)
        thunkAPI.dispatch(setOnboardingData(data));
      return {
        shops: data,
        page: body?.page || 1,
        pageSize: body?.pageSize || 10,
        isSuccessful,
      };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardDataById = createAsyncThunk(
  'cms/getDashboardDataById',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getDashboardDataByIdAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getGoogleSearchConsoleAuth = createAsyncThunk(
  'cms/getGoogleSearchConsoleAuth',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getGoogleSearchConsoleAuthAPI(params);
    if (isSuccessful) return { statusKey, data };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const addPublicShop = createAsyncThunk(
  'cms/addPublicShop',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await addPublicShopAPI(body);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardOverviewDataById = createAsyncThunk(
  'cms/getDashboardOverviewDataById',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardOverviewDataByIdAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardProductPerformance = createAsyncThunk(
  'cms/getDashboardProductPerformance',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardProductPerformanceAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardOptimizationPerformance = createAsyncThunk(
  'cms/getDashboardOptimizationPerformance',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardOptimizationPerformanceAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardOptimizationProducts = createAsyncThunk(
  'cms/getDashboardOptimizationProducts',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardOptimizationProductsAPI(body);
    if (isSuccessful) return { products: data, ...body };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(getDashboardData.pending, (state) => {
      state.loading = true;
    })
    .addCase(getDashboardData.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const { shops, page, pageSize } = action.payload;
      const oldShops = page >= 2 ? state.shopsPagination.list : [];
      const newShopsList = [...oldShops, ...shops].map((_s) => {
        return {
          ..._s,
          value: _s?.id,
          label: _s?.display_name ?? _s?.public_shop_url,
        };
      });
      state.shopsPagination = {
        currentPage: page,
        hasMore: shops.length === pageSize,
        list: newShopsList,
      };
      state.loading = false;
    })
    .addCase(getDashboardDataById.pending, (state) => {
      state.loading = true;
    })
    .addCase(getDashboardDataById.rejected, (state, action) => {
      state.loading = false;
      state.currentShopData = null;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getDashboardDataById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentShopData = action?.payload;
    })
    .addCase(getGoogleSearchConsoleAuth.pending, (state) => {
      state.loadingGoogleAuth = true;
    })
    .addCase(getGoogleSearchConsoleAuth.rejected, (state, action) => {
      state.loadingGoogleAuth = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getGoogleSearchConsoleAuth.fulfilled, (state, action) => {
      state.loadingGoogleAuth = false;
      const { statusKey, data } = action?.payload;
      nomenclatureSnack({
        type: 'success',
        message: statusKey,
      });
      window.open(data.url, '_blank');
    })
    .addCase(addPublicShop.pending, (state) => {
      state.loadingPublicShop = true;
    })
    .addCase(addPublicShop.rejected, (state, action) => {
      state.loadingPublicShop = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(addPublicShop.fulfilled, (state, action) => {
      state.loadingPublicShop = false;
    })
    .addCase(getDashboardOverviewDataById.pending, (state) => {
      state.loadingOverviewData = true;
    })
    .addCase(getDashboardOverviewDataById.rejected, (state) => {
      state.loadingOverviewData = false;
    })
    .addCase(getDashboardOverviewDataById.fulfilled, (state) => {
      state.loadingOverviewData = false;
    })
    .addCase(getDashboardProductPerformance.pending, (state) => {
      state.loadingIndividualPerformance = true;
    })
    .addCase(getDashboardProductPerformance.rejected, (state) => {
      state.loadingIndividualPerformance = false;
    })
    .addCase(getDashboardProductPerformance.fulfilled, (state) => {
      state.loadingIndividualPerformance = false;
    })
    .addCase(getDashboardOptimizationPerformance.pending, (state) => {
      state.loadingOptimizationPerformance = true;
    })
    .addCase(getDashboardOptimizationPerformance.rejected, (state) => {
      state.loadingOptimizationPerformance = false;
    })
    .addCase(getDashboardOptimizationPerformance.fulfilled, (state) => {
      state.loadingOptimizationPerformance = false;
    })
    .addCase(getDashboardOptimizationProducts.pending, (state) => {})
    .addCase(getDashboardOptimizationProducts.rejected, (state) => {})
    .addCase(getDashboardOptimizationProducts.fulfilled, (state, action) => {
      const { products, page } = action.payload;
      state.productsPagination = {
        currentPage: page,
        hasMore: products.length === 10,
      };
    });
};

export const cmsSlice = createSlice({
  name: 'dashboard',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setTargetedOptimizationRowsRenders: (state, action) => {
      state.targetedOptimizationTable.rowsRender = action.payload;
    },
    setOptimizationPerformanceData: (state, action) => {
      state.optimizationPerformanceData = action.payload;
    },
    setShopDataAdded: (state, action) => {
      let newShopData = action?.payload;
      const alreadyExist = state.shopsPagination?.list?.find(
        (_s) => _s?.id === newShopData?.id
      );
      if (!alreadyExist) {
        newShopData = {
          ...newShopData,
          value: newShopData?.id,
          label: newShopData?.display_name ?? newShopData?.public_shop_url,
        };
        const newList = [newShopData, ...state.shopsPagination.list];
        state.shopsPagination = {
          ...state.shopsPagination,
          list: newList,
        };
      }
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setTargetedOptimizationRowsRenders,
  setOptimizationPerformanceData,
  setShopDataAdded,
} = cmsSlice.actions;
export default cmsSlice.reducer;

// ------------------SELECTORS-------------
export const loadingDashboard = (state) => state.dashboard.loading;
export const loadingPublicShop = (state) => state.dashboard.loadingPublicShop;
export const selectLoadingOverviewData = (state) =>
  state.dashboard.loadingOverviewData;
export const selectLoadingIndividualPerformance = (state) =>
  state.dashboard.loadingIndividualPerformance;
export const selectLoadingOptimizationPerformance = (state) =>
  state.dashboard.loadingOptimizationPerformance;
export const selectTargetedOptimizationRows = (state) =>
  state.dashboard.targetedOptimizationTable.rowsRender;
export const currentShopData = (state) => state.dashboard.currentShopData;
export const selectOptimizationPerformanceData = (state) =>
  state.dashboard.optimizationPerformanceData;
export const selectProductsPagination = (state) =>
  state.dashboard.productsPagination;
export const selectShopsPagination = (state) => state.dashboard.shopsPagination;
